.fun-facts-container {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  color: white;
  overflow-y: scroll;

}

.credits {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  max-height: 100%;
  animation: scrollCredits 30s linear infinite; /* Increased duration */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.credits::-webkit-scrollbar {
  display: none; /* WebKit */
}

.credits:hover {
  animation-play-state: paused;
  cursor: all-scroll;
}

@keyframes scrollCredits {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
