body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 767.98px) {
 body {
  overflow: visible;
 }
}



.custom-link {
  text-decoration: none; /* Remove underline */
  color: white; /* Inherit the text color from parent */
  /* Add any other styling you want for the link */
}





#logo{


  font-family: 'Best Signature Font', sans-serif;

  }


  .aired {
    background-color: green;
  }


  .upcoming {
    background-color: red;
  }



  .image-slider {
    display: inline-flex;
    width: 100%;
    height: 300px; /* Set your desired height */
    /* animation: slide 4000s infinite alternate; Slide back and forth with a duration of 0.12 seconds per image */
    animation: slide 960s infinite alternate; /* Adjusted duration for smooth sliding */
  }



  .image-slider img {
    width: 200px; /* Each image takes up equal portion of container width */
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    float: left;
    padding: 2px;
}

.image-slider:hover {
  animation-play-state: paused; /* Stop the animation on hover */

}

.image-slider img:hover {
  transform: scale(1.1);
  opacity: 0.75;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  cursor: pointer;

}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}



.card-background {
  position: relative;
  background-image: url('https://res.cloudinary.com/dcllyqc40/image/upload/v1718143455/wallpaperflare.com_wallpaper_cbkakv.jpg');

  background-size: cover;
  background-position: center;
  color: white;
  padding: 20px;
}

.card-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1;
  border-radius: 0.25rem;
}

.card-background .card-body {
  position: relative;
  z-index: 2;
}


.social-button {
  margin-right: 10px; /* Adjust the margin as needed */
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover,
.list-group-item.active {
  background-color: #181818; /* Example highlight color */
}
.accordion-header{
  font-family: Dosis, sans-serif;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}



@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-48756px); /* Slide through all images */
  }
}


  @media (max-width: 576px) {
    .justify-content-center.align-items-center {
       flex-direction: column;
     }
   }


   @media (max-width: 768px) {
    .back-ground-image{
      display: none
    };

  }


  @media (max-width: 560px) {
    .plot-info {
      display: none

    }
  }

